import React from "react";
import Container from "../atoms/Container";
import Logo from '../../assets/svg/logo.inline.svg';
import styled from "styled-components";
import TextOnPath from "../atoms/TextOnPath";

const Holder = styled.header`
  position: relative;
  margin: 2rem auto;
  width: 80%;
  max-width: 30rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem auto;
  }

  > svg {
    display: block;
    #logo_inline_svg__top {
      fill: ${props => props.theme.primary};
    }

    #logo_inline_svg__bottom {
      fill: ${props => props.theme.secondary};
    }
  }

  h1 {
    svg {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 79%;
      height: auto;
      transform: translate(4%, 4%);
    }
  }
`;

function Header({title}) {
    return (
      <Container>
        <Holder>
          <Logo/>
          <h1><TextOnPath text={title}/></h1>
        </Holder>
      </Container>
    );
}

export default Header;
