import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Circle = styled.svg`
  path {
    fill: transparent;
    stroke: transparent;
  }
  text {
    text-align: justify;
    fill: ${props => props.theme.colours.white};
    font-size: ${props => props.length > 15 ? '1.8rem' : '2.4rem'};
    font-weight: 300;
    @media (${props => props.theme.breakpoints.md}) {
      font-size: ${props => props.length > 15 ? '2rem' : '2.8rem'};
    }
  }
`;

function TextOnPath({text}) {
    const length = text.length;
    let rotate = -16;
    let textLength = '105%';

    if ( length < 6 ) {
      rotate = -48;
      textLength = '60%';
    } else if ( length < 12 ) {
      rotate = -40;
      textLength = '70%';
    }

    return (
      <Circle viewBox="0 0 500 500" width="500" height="500" length={length}>
        <path id="circle"
              transform={`rotate(${rotate} 250 250)`}
              d="
                M 250 250
                m -200, 0
                a 200 200 0 0 0 400 0
                "
        />
        <text width="500" textLength={textLength} >
          <textPath xlinkHref="#circle" textLength={textLength} >{text}</textPath>
        </text>
      </Circle>
    )
}

TextOnPath.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextOnPath;